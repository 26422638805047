import { motion } from 'framer-motion';

import ExpeditionPlannerDesktopFooter from './ExpeditionPlannerDesktopFooter';

type TExpeditionPlannerDesktopWindow = {
  children: any;
  key: string;

  onCancel: () => void;
  onContinue: () => void;
};

const ExpeditionPlannerDesktopWindow = (
  props: TExpeditionPlannerDesktopWindow
) => {
  const { children, onCancel, onContinue, key } = props;
  return (
    <motion.div
      key={key}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.2 } }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
      className="absolute bottom-0 z-10 flex justify-center pt-5 translate-y-full"
    >
      <div className="overflow-hidden bg-white">
        <div className="p-8 overflow-y-auto max-h-[24rem]">{children}</div>
        <ExpeditionPlannerDesktopFooter
          onCancel={onCancel}
          onContinue={onContinue}
        />
      </div>
    </motion.div>
  );
};

export default ExpeditionPlannerDesktopWindow;
