import { forwardRef, useState } from 'react';
import clsx from 'clsx';

import { Icon } from '@atoms';
import { CheckboxMode } from '@constants';
import { CheckLine } from '@icons/System';

import Calendar1Icon from './Calendar1Icon';
import Calendar2Icon from './Calendar2Icon';
import Calendar3Icon from './Calendar3Icon';
import Calendar4Icon from './Calendar4Icon';

const CheckboxIcon = ({
  className,
  isDarkMode = false,
  ...props
}: {
  className: string;
  isDarkMode: boolean;
}) => (
  <Icon
    icon={CheckLine}
    className={clsx(className, {
      'text-primary-hrg-red': isDarkMode,
      'text-white': !isDarkMode
    })}
    {...props}
  />
);

const ExpeditionPlannerCheckbox = forwardRef<HTMLInputElement, ICheckboxProps>(
  (
    {
      containerClassNames,
      isDarkMode = false,
      label,
      hasError = false,
      hasSpaceBetween = false,
      truncate = false,
      mode = CheckboxMode.primary,
      disabled,
      checked,
      labelClassName,
      icon,
      ...props
    }: ICheckboxProps,
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const checkmarkBackground = clsx(
      'transition-all duration-300 relative',
      'top-0 left-0 h-7 w-7 items-center justify-center inline-block'
    );
    const absoluteCenter = clsx('m-auto absolute inset-0');
    const checkmarkBox = clsx('absolute flex-none w-6 h-6 border-2', {
      'border-white': isDarkMode,
      'border-primary-hx-indigo': !isDarkMode
    });
    const check = clsx('flex-none hidden');
    const checkedColour = checked ? 'white' : 'black';

    return (
      <div
        aria-invalid={hasError}
        className={clsx({
          'h-full': mode === CheckboxMode.secondary,
          [containerClassNames ?? '']: containerClassNames
        })}
      >
        <label
          className={clsx('relative flex items-start w-full m-0 body-text-1', {
            'justify-between flex-row-reverse': hasSpaceBetween,
            'h-[92px] w-[147px]': mode === CheckboxMode.secondary,
            'outline-penguin-yellow outline': isFocused
          })}
        >
          <div
            aria-hidden
            className="absolute top-[20px] left-[20px] pointer-events-none"
          >
            {icon === 'Calendar1' && <Calendar1Icon colour={checkedColour} />}
            {icon === 'Calendar2' && <Calendar2Icon colour={checkedColour} />}
            {icon === 'Calendar3' && <Calendar3Icon colour={checkedColour} />}
            {icon === 'Calendar4' && <Calendar4Icon colour={checkedColour} />}
          </div>
          <input
            data-testid="checkbox"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            ref={ref}
            className={clsx('absolute top-0 left-0 opacity-0 checkbox group', {
              'dark-mode': isDarkMode
            })}
            type="checkbox"
            checked={checked}
            disabled={disabled}
            {...props}
          />
          {mode === CheckboxMode.primary && (
            <span className={checkmarkBackground}>
              <span className={clsx(checkmarkBox, absoluteCenter)}>
                <CheckboxIcon
                  isDarkMode={isDarkMode}
                  className={clsx(check, absoluteCenter)}
                />
              </span>
            </span>
          )}
          <span
            data-testid="checkbox-inner-label"
            className={clsx(
              {
                'flex-1': !hasSpaceBetween,
                'whitespace-nowrap overflow-hidden overflow-ellipsis': truncate,
                'break-words': !truncate,
                'hover:bg-indigo-light-200':
                  CheckboxMode.secondary === mode && !checked,
                'bg-primary-hx-indigo text-white':
                  CheckboxMode.secondary === mode && checked,
                'px-[20px] text-center pt-[30px] secondary border border-warm-gray-4':
                  mode === CheckboxMode.secondary,
                'h-full flex items-center': mode === CheckboxMode.secondary,
                'text-white': isDarkMode
              },
              'font-medium',
              labelClassName
            )}
          >
            {label}
          </span>
        </label>
      </div>
    );
  }
);

ExpeditionPlannerCheckbox.displayName = 'Checkbox';

export default ExpeditionPlannerCheckbox;
