import { useState } from 'react';
import { Checkbox } from '@hurtigruten/design-system-components';

import { useTranslate } from '@hooks';
import { voyages_filter, common } from '@microcopies';
import { DurationRange } from '@src/types/expeditionPlanner';
import { CheckboxMode } from '@constants';

import ExpeditionPlannerCheckbox from './ExpeditionPlannerCheckbox';
import { durationRangeOptions } from './MobilePlannerFilters';

type TExpeditionPlannerDesktopDuration = {
  onChange: (selectedItems: DurationRange[] | null | 'all') => void;
  filterState: DurationRange[] | 'all' | null;
};

const ExpeditionPlannerDesktopDuration = (
  props: TExpeditionPlannerDesktopDuration
) => {
  const { onChange, filterState } = props;
  const [selectAll, setSelectAll] = useState(false);

  const mappedDurationOptions = durationRangeOptions.map((duration, i) => ({
    ...duration,
    icon: `Calendar${i + 1}`
  }));

  const filterTranslate = useTranslate(voyages_filter, (x) => x.voyages.filter);
  const commonTranslate = useTranslate(common, (x) => x.common);

  const durationIsSelected = (duration: DurationRange) =>
    filterState &&
    Array.isArray(filterState) &&
    filterState.some((a) => a.min === duration.min && a.max === duration.max);

  const filterOutDurations = (duration: DurationRange) =>
    filterState && Array.isArray(filterState)
      ? filterState.filter(
          (o) => o.min !== duration.min && o.max !== duration.max
        )
      : [];

  const handleChange = (duration: DurationRange) => {
    let result = Array.isArray(filterState) ? filterState : [];

    if (selectAll) {
      result = durationRangeOptions.map((x) => x.value);
      setSelectAll(false);
    }

    if (durationIsSelected(duration)) {
      result = filterOutDurations(duration);
    } else {
      result = [...result, duration];
    }

    onChange(result.length === 0 ? null : result);
  };

  return (
    <div
      data-testid="expedition-planner-duration"
      className="flex flex-col mb-6 gap-y-6"
    >
      <div className="flex items-center justify-between">
        <h6 className="font-medium leading-5 text-md">
          {filterTranslate((x) => x.howManyNights)}
        </h6>
        <Checkbox
          id="select-all"
          checked={selectAll}
          label={commonTranslate((x) => x.selectAll)}
          value="true"
          onClick={() => {
            setSelectAll(!selectAll);
            onChange(null);
          }}
        />
      </div>
      <div
        data-testid="expedition-planner-duration-items"
        className="flex gap-7"
      >
        {mappedDurationOptions.map((duration) => (
          <fieldset
            aria-labelledby="checkbox"
            key={duration.id}
            className="flex-1 w-[147px]"
          >
            <ExpeditionPlannerCheckbox
              checked={
                (selectAll || durationIsSelected(duration.value)) ?? false
              }
              onChange={() => handleChange(duration.value)}
              label={duration.name}
              mode={CheckboxMode.secondary}
              icon={duration.icon}
            />
          </fieldset>
        ))}
      </div>
    </div>
  );
};

export default ExpeditionPlannerDesktopDuration;
