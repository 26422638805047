import { getSharedEnvironmentVariables } from '@src/utils/environmentVariables';

import useLocale from './useLocale';

export const useSeoWebsiteMarkup = () => {
  const locale = useLocale();
  const sharedEnvironmentVariables = getSharedEnvironmentVariables();

  return {
    '@context': 'https://schema.org/',
    '@type': 'WebSite',
    name: 'Hurtigruten',
    url: `${sharedEnvironmentVariables.WEBSITE_URL ?? '/'}${locale}/`,
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `${
          sharedEnvironmentVariables.WEBSITE_URL ?? '/'
        }${locale}/search/?query={search_term_string}`
      },
      'query-input': `required name=search_term_string`
    }
  };
};
