/* eslint-disable no-underscore-dangle */
import { Biography } from '@content/models/biography';
import { CruiseCard } from '@content/models/cruiseCard';
import { ImageGallery } from '@content/models/imageGallery';
import {
  LandingPageCampaign,
  LandingPageSection,
  LandingPageStory
} from '@content/models/landingPage';
import { MustSeeDestinationCard } from '@content/models/mustSeeDestinationCard';
import {
  OfferWrapper,
  FeatureOfferWrapper
} from '@content/models/offerWrapper';
import { Story } from '@content/models/story';
import { VideoWrapper } from '@content/models/videoWrapper';
import {
  ImageGalleryData,
  OfferWrapperData
} from '@content/models/campaignAlternative';
import { BiographyFragmentData } from '@content/queries/fragments/biography';

export const isCruiseCard = (s: { __typename?: string }): s is CruiseCard =>
  s.__typename === 'CruiseCard';

export const isMustSeeDestinationCard = (s: {
  __typename?: string;
}): s is MustSeeDestinationCard => s.__typename === 'MustSeeDestinationCard';

export const isLandingPageCampaign = (s: {
  __typename?: string;
}): s is LandingPageCampaign => s.__typename === 'LandingPageCampaign';

export const isLandingPageStory = (s: {
  __typename?: string;
}): s is LandingPageStory => s.__typename === 'LandingPageStory';

export const isLandingPageStoryOrCampaign = (s: {
  __typename?: string;
}): s is LandingPageStory | LandingPageCampaign =>
  isLandingPageStory(s) || isLandingPageCampaign(s);

export const isStory = (s: { __typename?: string }): s is Story =>
  s.__typename === 'Story';

export const isLandingPageSection = (s: {
  __typename?: string;
}): s is LandingPageSection => s.__typename === 'HomepageBlock';

export const isOfferWrapper = (s: { __typename?: string }): s is OfferWrapper =>
  s.__typename === 'OfferWrapper';
export const isVideoWrapper = (obj: {
  __typename: string;
}): obj is VideoWrapper => obj.__typename === 'VideoWrapper';

export const isBiography = (obj: { __typename: string }): obj is Biography =>
  obj.__typename === 'Biography';

export const isImageGallery = (obj: {
  __typename: string;
}): obj is ImageGallery => obj.__typename === 'ImageGallery';

export const isBiographyData = (obj: {
  __typename: string;
}): obj is BiographyFragmentData => obj.__typename === 'Biography';

export const isImageGalleryData = (obj: {
  __typename: string;
}): obj is ImageGalleryData => obj.__typename === 'ImageGallery';

export const isOfferWrapperData = (obj: {
  __typename: string;
}): obj is OfferWrapperData => obj.__typename === 'OfferWrapper';

export const isFeatureOffer = (obj: {
  __typename: string;
}): obj is FeatureOfferWrapper =>
  obj.__typename === 'B2cExpeditionOfferWrapper';
