import { ICalendarIconProps } from '@src/types/expeditionPlanner';

const Calendar4Icon = (props: ICalendarIconProps) => {
  const { colour } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
    >
      <path
        d="M22.8 2.8H18.4V0H16.8V2.8H7.2V0H5.6V2.8H1.2C0.881854 2.80037 0.576845 2.92692 0.351881 3.15188C0.126918 3.37684 0.000370522 3.68185 0 4V20.8C0.000370522 21.1181 0.126918 21.4232 0.351881 21.6481C0.576845 21.8731 0.881854 21.9996 1.2 22H22.8C23.1181 21.9996 23.4232 21.8731 23.6481 21.6481C23.8731 21.4232 23.9996 21.1181 24 20.8V4C23.9996 3.68185 23.8731 3.37684 23.6481 3.15188C23.4232 2.92692 23.1181 2.80037 22.8 2.8ZM22.4 20.4H1.6V4.4H5.6V6.4H7.2V4.4H16.8V6.4H18.4V4.4H22.4V20.4Z"
        fill={colour}
      />
      <path d="M6.3998 9.20001H4.7998V10.8H6.3998V9.20001Z" fill={colour} />
      <path d="M10.7997 9.20001H9.19971V10.8H10.7997V9.20001Z" fill={colour} />
      <path d="M14.8 9.20001H13.2V10.8H14.8V9.20001Z" fill={colour} />
      <path d="M19.2001 9.20001H17.6001V10.8H19.2001V9.20001Z" fill={colour} />
      <path d="M6.3998 12.8H4.7998V14.4H6.3998V12.8Z" fill={colour} />
      <path d="M10.7997 12.8H9.19971V14.4H10.7997V12.8Z" fill={colour} />
      <path d="M14.8 12.8H13.2V14.4H14.8V12.8Z" fill={colour} />
      <path d="M19.2001 12.8H17.6001V14.4H19.2001V12.8Z" fill={colour} />
      <path d="M6.3998 16.4H4.7998V18H6.3998V16.4Z" fill={colour} />
      <path d="M10.7997 16.4H9.19971V18H10.7997V16.4Z" fill={colour} />
      <path d="M14.8 16.4H13.2V18H14.8V16.4Z" fill={colour} />
      <rect x="18" y="17" width="6" height="5" fill="white" />
      <path d="M17.5001 16.5L24.0001 17L18 22L17.5001 16.5Z" fill={colour} />
    </svg>
  );
};

export default Calendar4Icon;
