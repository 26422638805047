import { MutableRefObject } from 'react';

const HEADER_HEIGHT = 136;

export const scrollToRef = (
  refs: MutableRefObject<(HTMLDivElement | null)[]>,
  index: number,
  offsetTop?: number
) => {
  const refToScrollTo = refs.current[index];
  if (refToScrollTo) {
    const y =
      (refToScrollTo?.getBoundingClientRect().top ?? 0) +
      window.scrollY -
      (offsetTop || HEADER_HEIGHT);

    window.scrollTo({
      top: y,
      behavior: 'smooth'
    });
  }
};
