import React, { useRef, useEffect, useState } from 'react';
import { GetServerSidePropsContext } from 'next';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { Paragraph } from '@hurtigruten/design-system-components';
import { useDecision } from '@optimizely/react-sdk';
import clsx from 'clsx';

import {
  Layout,
  LandingPageHero,
  PromoBanner,
  WaveCampaignHero,
  DowntimeBanner
} from '@organisms';
import { Fluid } from '@components';
import { PageSection } from '@atoms';
import { landing } from '@microcopies';
import {
  useAppContext,
  useMediaQuery,
  useSeoWebsiteMarkup,
  useTranslate
} from '@hooks';
import { isLocale } from '@utils/typeGuards';
import { mapLocaleToContenfulFormat, breakpoints } from '@utils';
import useLocale, { defaultLocale } from '@hooks/useLocale';
import { metaDescription } from '@microcopies/sets/metaDescription';
import { getAllDestinationCards } from '@content/requests/getAllDestionationCards';
import { getLandingPage } from '@content/requests/contentApi/getLandingPage';
import {
  LandingPage,
  LandingPageSection,
  Sections,
  toTStory
} from '@content/models/landingPage';
import { StoryCard } from '@content/models/storyCard';
import { TPromoBanner } from '@content/models/editorialContent';
import {
  isCruiseCard,
  isLandingPageStory,
  isLandingPageStoryOrCampaign,
  isMustSeeDestinationCard,
  isOfferWrapper
} from '@content/utils/typeguards';
import { toNewDestinationModel } from '@content/models/mustSeeDestinationCard';
import { mapLocaleToCruisesUri } from '@src/utils/mappers/uriMappers';
import {
  CruiseFilterData,
  PlannerDestination
} from '@src/types/expeditionPlanner';
import { DestinationLinkCard } from '@content/models';
import { CruiseCard } from '@content/models/cruiseCard';
import { getCruiseFilterData } from '@content/requests/contentApi/getCruiseFilterDataQuery';
import { getDestinationCards } from '@content/requests/getDestinationCards';
import { scrollToRef } from '@src/utils/scrollToRef';
import { searchCruisesButtonReducerTypes } from '@context/searchCruisesButtonReducer';
import { getValidLocales } from '@constants/locales';
import WhyExpeditions from '@components/organisms/WhyExpeditions';
import ExplorerOffers from '@components/organisms/ExplorerOffers';
import MustSeeDestinations from '@components/organisms/MustSeeDestinations';
import ReadyToExplore from '@components/organisms/ReadyToExplore';
import AssociatedVoyages from '@components/organisms/AssociatedVoyages';
import { HrefLangs } from '@components/seo/HrefLang';
import { getServerSidePropsCacheWrapper } from 'utils/getServerSidePropsCacheWrapper';

const DynamicInspirationalStories = dynamic(
  () => import('@components/organisms/InspirationalStories'),
  {
    ssr: false
  }
);

type TBackgroundColor = 'white' | 'gray' | 'hx-caviar';

const StoriesSection = ({
  backgroundColor,
  stories
}: {
  backgroundColor?: TBackgroundColor;
  stories?:
    | Contentful.THomepageSections[]
    | Contentful.TStory[]
    | (StoryCard | Contentful.TStory)[];
}) => {
  const translateLanding = useTranslate(landing, (x) => x.landing);

  return (
    <PageSection
      backgroundColor={backgroundColor}
      title={translateLanding((x) => x.storiesTitle)}
    >
      <Fluid>
        <div className="w-full">
          {stories && <DynamicInspirationalStories stories={stories} />}
        </div>
      </Fluid>
    </PageSection>
  );
};

const VoyagesSection = ({
  backgroundColor,
  promoBanner,
  isDonwtimeBannerEnabled,
  voyages
}: {
  backgroundColor?: TBackgroundColor;
  promoBanner?: TPromoBanner | null;
  voyages?: CruiseCard[];
  isDonwtimeBannerEnabled?: boolean;
}) => {
  const translateLanding = useTranslate(landing, (x) => x.landing);
  const locale = useLocale();
  return (
    <>
      <PageSection backgroundColor={backgroundColor}>
        <Fluid>
          <div className="w-full">
            <h2
              className={clsx('mb-8 h3-text', {
                'text-white': backgroundColor === 'hx-caviar'
              })}
            >
              {translateLanding((x) => x.cruisesTitle)}
            </h2>
            {isDonwtimeBannerEnabled && (
              <DowntimeBanner containerClassName="mb-8" />
            )}
            {voyages && (
              <AssociatedVoyages
                voyages={voyages}
                showMoreLabel={translateLanding((x) => x.cruisesButton)}
                showMoreHref={`/${mapLocaleToCruisesUri(locale)}`}
                isDisplayNavigationButton
              />
            )}
          </div>
        </Fluid>
      </PageSection>
      {promoBanner && <PromoBanner promo={promoBanner} />}
    </>
  );
};

const sectionIds = {
  [Sections.OfferCarousel]: 'featured-offers',
  [Sections.WhyHRG]: 'why-hurtigruten',
  [Sections.MustSeeDestinations]: 'must-see-destinations',
  [Sections.FeaturedVoyages]: 'featured-cruises',
  [Sections.Stories]: 'inspirational-stories'
};

const sectionRefAccessor = {
  [Sections.OfferCarousel]: 1,
  [Sections.WhyHRG]: 3,
  [Sections.MustSeeDestinations]: 0,
  [Sections.FeaturedVoyages]: 2,
  [Sections.Stories]: 4
};

const getSectionComponent = (
  section: LandingPageSection
): React.ElementType => {
  switch (section.sectionType) {
    case Sections.OfferCarousel:
      return ExplorerOffers;
    case Sections.WhyHRG:
      return WhyExpeditions;
    case Sections.MustSeeDestinations:
      return MustSeeDestinations;
    case Sections.FeaturedVoyages:
      return VoyagesSection;
    case Sections.Stories:
      return StoriesSection;
    default:
      return Paragraph;
  }
};

const getAllSectionProps = (
  section: LandingPageSection,
  index: number,
  promoBanner?: TPromoBanner | null,
  allDestinationCards?: DestinationLinkCard[] | null,
  isDonwtimeBannerEnabled?: boolean
) => {
  switch (section.sectionType) {
    case Sections.OfferCarousel:
      return {
        sectionProps: {
          isDark: Boolean(index % 2),
          title: section.title,
          description: section.shortDescription,
          offers: section.subsections.filter(isOfferWrapper)
        },
        containerProps: {}
      };
    case Sections.WhyHRG:
      return {
        sectionProps: {
          isDark: Boolean(index % 2),
          title: section.title,
          shortDescription: section.shortDescription,
          storiesAndCampaigns: section.subsections.filter(
            isLandingPageStoryOrCampaign
          )
        },
        containerProps: {}
      };
    case Sections.MustSeeDestinations:
      return {
        containerProps: {
          className: 'laptop:min-h-[795px]'
        },
        sectionProps: {
          allDestinationCards,
          backgroundColor:
            index % 2 ? ('hx-caviar' as const) : ('gray' as const),
          destinations: section.subsections
            .filter(isMustSeeDestinationCard)
            .map(toNewDestinationModel)
        }
      };
    case Sections.FeaturedVoyages:
      return {
        sectionProps: {
          backgroundColor:
            index % 2 ? ('hx-caviar' as const) : ('gray' as const),
          voyages: section.subsections.filter(isCruiseCard),
          isDisplayNavigationButton: true,
          promoBanner,
          isDonwtimeBannerEnabled
        },
        containerProps: {}
      };
    case Sections.Stories:
      return {
        sectionProps: {
          backgroundColor:
            index % 2 ? ('hx-caviar' as const) : ('gray' as const),
          stories: section.subsections.filter(isLandingPageStory).map(toTStory)
        },
        containerProps: {}
      };
    default:
      return {
        sectionProps: {},
        containerProps: {}
      };
  }
};

const Home = ({
  allDestinationCards,
  landingPageData,
  plannerDestinations,
  plannerCruises
}: {
  allDestinationCards: DestinationLinkCard[] | null;
  landingPageData: LandingPage;
  plannerDestinations: PlannerDestination[];
  plannerCruises: CruiseFilterData[];
}) => {
  const translateMeta = useTranslate(metaDescription, (x) => x.metaDescription);
  const translateLanding = useTranslate(landing, (x) => x.landing);
  const isLaptop = useMediaQuery(breakpoints.laptop);
  const locale = useLocale();
  const websiteMarkup = useSeoWebsiteMarkup();
  const [decision] = useDecision('homepage_hero');
  const [ukDecision] = useDecision('homepage_hero__uk_');
  const [downtimeBanner] = useDecision('availability_downtime_banner');
  const { enabled: isAbTestEnabled } = decision;
  const { enabled: isUKAbTestEnabled } = ukDecision;
  const { enabled: isDonwtimeBannerEnabled } = downtimeBanner;
  const [engagedPlanner, setEngagedPlanner] = useState(false);

  const sectionRefs = useRef<Array<HTMLDivElement | null>>([]);
  const scrollToSection = (section: string) => {
    const index = Object.values(sectionIds).indexOf(section.substring(1));
    if (index !== -1) {
      scrollToRef(sectionRefs, index, isLaptop ? 90 : 50);
    }
  };

  const hasEngagedPlanner = () => {
    setEngagedPlanner(true);
  };

  useEffect(() => {
    window.optimizely = window.optimizely || [];

    window.optimizely.push({
      type: 'user',
      attributes: {
        hasEngagedExpeditionPlanner: engagedPlanner.toString()
      }
    });
  }, [engagedPlanner]);

  const { dispatch } = useAppContext();
  useEffect(() => {
    dispatch({
      type: searchCruisesButtonReducerTypes.HIDE
    });
  }, []);

  const expeditionPlannerBanner: Contentful.THeroVideo = landingPageData.banners
    .filter((b) => b.videoUrl)
    .map((banner) => ({
      description: banner.description ?? '',
      body: banner.body,
      cta: banner.cta,
      offer: banner.offer,
      title: banner.title ?? '',
      subHeading: banner.subHeading ?? '',
      imageUrl: banner.url ?? '',
      videoUrl: banner.videoUrl ?? ''
    }))?.[0];

  const trackHeroGalleryCTAClick = (href: string) => {
    if (href.startsWith('#')) {
      scrollToSection(href);
    }
  };

  return (
    <Layout
      title={translateMeta((x) => x.homePage.title)}
      metaDescription={translateMeta((x) => x.homePage.description)}
      isBackground
      dataTestId="main-page"
    >
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(websiteMarkup)
          }}
        />
      </Head>
      <HrefLangs entries={getValidLocales()} mappers={[]} />
      <h1 className="sr-only">{translateLanding((x) => x.mainTitle)}</h1>
      {locale === 'en-gb' && isUKAbTestEnabled && (
        <WaveCampaignHero
          cta={expeditionPlannerBanner?.cta ?? null}
          onCtaClick={trackHeroGalleryCTAClick}
          title={expeditionPlannerBanner?.title ?? ''}
          subHeading={expeditionPlannerBanner?.subHeading ?? ''}
          description={expeditionPlannerBanner?.description ?? ''}
          body={expeditionPlannerBanner?.body ?? null}
          offer={expeditionPlannerBanner?.offer ?? null}
          image={{
            url: expeditionPlannerBanner?.imageUrl ?? '',
            alt:
              expeditionPlannerBanner?.description ??
              expeditionPlannerBanner?.title ??
              ''
          }}
          video={{
            url: expeditionPlannerBanner?.videoUrl ?? '',
            title: expeditionPlannerBanner?.title ?? ''
          }}
          plannerDestinations={plannerDestinations}
          plannerCruises={plannerCruises}
          trackPlannerEngagement={() => hasEngagedPlanner()}
        />
      )}
      {locale !== 'en-gb' && isAbTestEnabled && (
        <WaveCampaignHero
          cta={expeditionPlannerBanner?.cta ?? null}
          onCtaClick={trackHeroGalleryCTAClick}
          title={expeditionPlannerBanner?.title ?? ''}
          subHeading={expeditionPlannerBanner?.subHeading ?? ''}
          description={expeditionPlannerBanner?.description ?? ''}
          body={expeditionPlannerBanner?.body ?? null}
          offer={expeditionPlannerBanner?.offer ?? null}
          image={{
            url: expeditionPlannerBanner?.imageUrl ?? '',
            alt:
              expeditionPlannerBanner?.description ??
              expeditionPlannerBanner?.title ??
              ''
          }}
          video={{
            url: expeditionPlannerBanner?.videoUrl ?? '',
            title: expeditionPlannerBanner?.title ?? ''
          }}
          plannerDestinations={plannerDestinations}
          plannerCruises={plannerCruises}
          trackPlannerEngagement={() => hasEngagedPlanner()}
        />
      )}
      {locale !== 'en-gb' && !isAbTestEnabled && (
        <LandingPageHero
          cta={expeditionPlannerBanner?.cta ?? null}
          onCtaClick={trackHeroGalleryCTAClick}
          title={expeditionPlannerBanner?.title ?? ''}
          subHeading={expeditionPlannerBanner?.subHeading ?? ''}
          description={expeditionPlannerBanner?.description ?? ''}
          body={expeditionPlannerBanner?.body ?? null}
          offer={expeditionPlannerBanner?.offer ?? null}
          image={{
            url: expeditionPlannerBanner?.imageUrl ?? '',
            alt:
              expeditionPlannerBanner?.description ??
              expeditionPlannerBanner?.title ??
              ''
          }}
          video={{
            url: expeditionPlannerBanner?.videoUrl ?? '',
            title: expeditionPlannerBanner?.title ?? ''
          }}
          plannerDestinations={plannerDestinations}
          plannerCruises={plannerCruises}
          trackPlannerEngagement={() => hasEngagedPlanner()}
        />
      )}
      {locale === 'en-gb' && !isUKAbTestEnabled && (
        <LandingPageHero
          cta={expeditionPlannerBanner?.cta ?? null}
          onCtaClick={trackHeroGalleryCTAClick}
          title={expeditionPlannerBanner?.title ?? ''}
          subHeading={expeditionPlannerBanner?.subHeading ?? ''}
          description={expeditionPlannerBanner?.description ?? ''}
          body={expeditionPlannerBanner?.body ?? null}
          offer={expeditionPlannerBanner?.offer ?? null}
          image={{
            url: expeditionPlannerBanner?.imageUrl ?? '',
            alt:
              expeditionPlannerBanner?.description ??
              expeditionPlannerBanner?.title ??
              ''
          }}
          video={{
            url: expeditionPlannerBanner?.videoUrl ?? '',
            title: expeditionPlannerBanner?.title ?? ''
          }}
          plannerDestinations={plannerDestinations}
          plannerCruises={plannerCruises}
          trackPlannerEngagement={() => hasEngagedPlanner()}
        />
      )}
      {landingPageData?.sections.map((section, index) => {
        const SectionComponent = getSectionComponent(section);
        const { sectionProps, containerProps } = getAllSectionProps(
          section,
          index,
          landingPageData.promoBanner,
          allDestinationCards,
          isDonwtimeBannerEnabled
        );
        return (
          <div
            ref={(el) => {
              sectionRefs.current[sectionRefAccessor[section.sectionType]] = el;
            }}
            key={sectionIds[section.sectionType]}
            id={sectionIds[section.sectionType]}
            {...containerProps}
          >
            <SectionComponent {...sectionProps} />
          </div>
        );
      })}

      <ReadyToExplore bannerImageUrl={expeditionPlannerBanner?.imageUrl} />
    </Layout>
  );
};

export default Home;

const gssp = async ({ res, locale: urlLocale }: GetServerSidePropsContext) => {
  const locale = isLocale(urlLocale) ? urlLocale : defaultLocale;
  const contentfulLanguage = mapLocaleToContenfulFormat(
    isLocale(locale) ? locale : defaultLocale
  );

  const landingPageData = await getLandingPage({
    locale: contentfulLanguage
  });

  if (landingPageData === null) {
    return {
      notFound: true
    };
  }

  const [destinationCards, plannerCruises] = await Promise.all([
    getDestinationCards({ locale: contentfulLanguage }),
    getCruiseFilterData({ locale: contentfulLanguage })
  ]);

  const plannerDestinations = destinationCards.map((card) => ({
    // Using slug as id because that is hopefully
    // in sync between new and old destinations
    id: card.slug,
    name: card.shortName,
    imageUrl: card.heroBanner.imageUrl,
    imageAlt: card.heroBanner.imageAlt
  }));

  // Browser cache: 5 min, CDN cache: 35 min
  res.setHeader('Cache-Control', 'public, max-age=300, s-maxage=2100');

  const allDestinationCards = await getAllDestinationCards({
    locale: contentfulLanguage
  });

  return {
    props: {
      allDestinationCards,
      landingPageData,
      plannerDestinations,
      plannerCruises
    }
  };
};

export const getServerSideProps = getServerSidePropsCacheWrapper()(gssp);
