import { Button } from '@hurtigruten/design-system-components';

import { useTranslate } from '@hooks';
import { expeditionPlanner } from '@microcopies';

type TExpeditionPlannerDesktopFooter = {
  onContinue: () => void;
  onCancel: () => void;
};

const ExpeditionPlannerDesktopFooter = (
  props: TExpeditionPlannerDesktopFooter
) => {
  const { onContinue, onCancel } = props;
  const translate = useTranslate(expeditionPlanner, (x) => x.expeditionPlanner);

  return (
    <div className="flex justify-between w-full p-4 border-t">
      <Button
        size="small"
        appearance="tertiary"
        onClick={onCancel}
        text={translate((x) => x.cancel)}
      />
      <Button
        size="small"
        appearance="primary"
        onClick={onContinue}
        text={translate((x) => x.next)}
      />
    </div>
  );
};

export default ExpeditionPlannerDesktopFooter;
