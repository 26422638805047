import { useState, useReducer } from 'react';
import { Button } from '@hurtigruten/design-system-components';

import { IconButton } from '@atoms';
import { SearchLine } from '@icons/System';
import { Drawer } from '@components/molecules';
import { PlannerProps } from '@src/types/expeditionPlanner';

import MobilePlannerFilters from './MobilePlannerFilters';
import {
  mobileFilterReducer,
  initialMobileFilterState,
  ResetAction
} from './mobileFilterReducer';

const MobileExpeditionPlanner = ({
  resultUrl,
  resultCount,
  destinations,
  selectedDestinations,
  onSelectDestinations,
  selectedDurationRange,
  onSelectDurationRange,
  onSelectDateRange,
  selectedDateRange,
  trackingEvent,
  i18n: {
    whereLabel,
    whereEmptyText,
    whenLabel,
    whenEmptyText,
    durationLabel,
    durationEmptyText,
    searchButtonText,
    searchPlaceholder,
    clearButtonText
  }
}: PlannerProps) => {
  const [open, setOpen] = useState(false);

  const [mobileFilterState, dispatch] = useReducer(
    mobileFilterReducer,
    initialMobileFilterState
  );

  return (
    <div className="flex justify-center col-span-10 col-start-2">
      <div
        onClick={() => {
          setOpen(true);
          trackingEvent('Filter Interaction (Mobile)', 'opened');
        }}
        className="flex flex-row items-center justify-between w-full h-16 pl-6 bg-white"
      >
        <button className="text-left grow-9999">{searchPlaceholder}</button>
        <IconButton
          className="mx-4"
          aria-label="Close"
          buttonColor="hxIndigo"
          icon={SearchLine}
        />
      </div>
      <Drawer
        alwaysOpenFromBottom
        isOpen={open}
        onClose={() => {
          setOpen(false);
          trackingEvent('Filter Interaction (Mobile)', 'closed');
        }}
        hideDefaultButton
      >
        <div className="px-6 pb-6">
          <MobilePlannerFilters
            {...{
              selectedDurationRange:
                selectedDurationRange === 'all' ? null : selectedDurationRange,
              onSelectDurationRange,
              selectedDestinations:
                selectedDestinations === 'all' ? null : selectedDestinations,
              onSelectDestinations,
              onSelectDateRange,
              selectedDateRange,
              destinations,
              whereLabel,
              whereEmptyText,
              whenLabel,
              whenEmptyText,
              durationLabel,
              durationEmptyText,
              mobileFilterState,
              dispatch
            }}
          />
          <div className="flex justify-center pt-6">
            <div>
              <Button
                onClick={() => {
                  onSelectDateRange(null);
                  onSelectDurationRange(null);
                  onSelectDestinations(null);
                  dispatch({ type: ResetAction.RESET });
                }}
                text={clearButtonText}
                appearance="tertiary"
                size="regular"
              />
            </div>
            <div className="w-full ml-6">
              <Button
                text={searchButtonText}
                href={resultUrl}
                appearance="primary"
                icon="search"
                size="regular"
                disabled={resultCount === 0}
              />
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default MobileExpeditionPlanner;
