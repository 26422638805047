import { forwardRef } from 'react';

import { Icon, IconButton } from '@atoms';
import {
  ArrowDropDownLine,
  ArrowDropUpLine,
  CloseLine
} from '@components/icons/System';

type PlannerTabProps = {
  label: string;
  selections?: string;
  isOpen: boolean;
  hideBorder: boolean;
  selectionText: string;
  onClick: () => void;
  onClearSelection: () => void;
};

const ExpeditionPlannerDesktopButton = forwardRef<
  HTMLButtonElement,
  PlannerTabProps
>(({ label, selectionText, isOpen, onClick, onClearSelection }, ref) => {
  const handleClick = () => {
    onClick();
  };

  const handleClearSelection = () => {
    onClearSelection();
  };

  return (
    <button
      ref={ref}
      type="button"
      role="tab"
      aria-selected={isOpen}
      aria-controls="expedition-planner-tabs"
      onClick={handleClick}
      className="flex px-6 text-left rounded-full bg-red focus:outline-none focus-visible:outline-2 focus-visible:outline-penguin-yellow"
    >
      <div className="flex">
        <div>
          <div className="font-medium text-start text-[12px] uppercase">
            {label}
          </div>
          <div className="flex">
            <span className="text-base truncate md:text-sm">
              {selectionText}
            </span>
            <Icon
              className="pointer-events-none h-7"
              fill="black"
              icon={isOpen ? ArrowDropUpLine : ArrowDropDownLine}
            />
          </div>
        </div>
        <div className="flex items-center ml-2">
          {isOpen && (
            <IconButton
              buttonColor="hxGray"
              className="w-8 h-8"
              icon={CloseLine}
              aria-label="Clear selection"
              onClick={handleClearSelection}
            />
          )}
        </div>
      </div>
    </button>
  );
});

ExpeditionPlannerDesktopButton.displayName = 'ExpeditionPlannerDesktopButton';

export default ExpeditionPlannerDesktopButton;
