import clsx from 'clsx';
import React from 'react';

import { Fluid } from '@components';

const PageSection = ({
  backgroundColor,
  children,
  isNoPaddingBottom = false,
  isNoPaddingTop = false,
  isSmallPadding = false,
  leadText,
  title,
  id
}: {
  backgroundColor?: 'white' | 'gray' | 'hx-caviar' | 'warm-gray-1';
  children: React.ReactNode;
  isNoPaddingBottom?: boolean;
  isNoPaddingTop?: boolean;
  isSmallPadding?: boolean;
  leadText?: string | React.ReactNode;
  title?: string;
  id?: string;
}) => (
  <section
    id={id ?? ''}
    className={clsx('py-8 laptop:py-20', {
      'bg-white': backgroundColor === 'white',
      'bg-hx-caviar': backgroundColor === 'hx-caviar',
      'bg-warm-gray-2': backgroundColor === 'gray',
      'warm-gray-1': backgroundColor === 'warm-gray-1',
      'laptop:py-12': isSmallPadding,
      '!pb-0': isNoPaddingBottom,
      '!pt-0': isNoPaddingTop
    })}
  >
    {(title || leadText) && (
      <Fluid
        className={clsx('flex-col items-start gap-2 mb-6', {
          'text-white': backgroundColor === 'hx-caviar'
        })}
      >
        {title && (
          <h2
            className={clsx('h3-text', {
              'laptop:mb-12': !leadText,
              'mb-2 laptop:mb-4': leadText,
              'text-white': backgroundColor === 'hx-caviar'
            })}
          >
            {title}
          </h2>
        )}
        {leadText && (
          <>
            {typeof leadText === 'string' ? (
              <p className="w-full mb-0 laptop:mb-4 h6-text tablet:w-8/12">
                {leadText}
              </p>
            ) : (
              <div className="w-full mb-0 bulleted-child-ul laptop:mb-4 tablet:w-8/12">
                {leadText}
              </div>
            )}
          </>
        )}
      </Fluid>
    )}
    {children}
  </section>
);

export default PageSection;
