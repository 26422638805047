import { useRef, useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import clsx from 'clsx';
import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';

import { Icon, Button } from '@atoms';
import { ButtonModes } from '@constants';
import { SearchLine } from '@components/icons/System';
import { PlannerProps } from '@src/types/expeditionPlanner';
import { listSummary } from '@src/utils/listSummary';
import {
  addMissingDates,
  selectedDataRangeText
} from '@src/utils/departureFilter';
import { useLocale, usePrevious } from '@hooks';

import ExpeditionPlannerDesktopButton from './ExpeditionPlannerDesktopButton';
import ExpeditionPlannerDesktopWindow from './ExpeditionPlannerDesktopWindow';
import ExpeditionPlannerDesktopDuration from './ExpeditionPlannerDesktopDuration';
import DestinationFilter from './DestinationFilter';
import DateFilter from './DateFilter';
import { durationValuesToString } from './MobilePlannerFilters';

enum Steps {
  None = 0,
  Destination = 1,
  Dates = 2,
  Duration = 3
}

const ExpeditionPlannerDesktop = (props: PlannerProps) => {
  const router = useRouter();
  const searchButtonRef = useRef<HTMLButtonElement>(null);
  const whereButtonRef = useRef<HTMLButtonElement>(null);
  const whenButtonRef = useRef<HTMLButtonElement>(null);
  const howLongButtonRef = useRef<HTMLButtonElement>(null);
  const expeditionPlannerContainerRef = useRef<HTMLDivElement>(null);

  const getRefRect = (
    ref: React.RefObject<HTMLButtonElement | HTMLDivElement>
  ) => ref.current?.getBoundingClientRect() ?? null;

  const [targetRect, setTargetRect] = useState<DOMRect | null>(null);

  const locale = useLocale();
  const [step, setStep] = useState(Steps.None);
  const previousStep = usePrevious(step);

  useEffect(() => {
    if (previousStep === Steps.Dates && step !== Steps.Dates) {
      /* we just closed the date filter and need to 
      check if both dates have been selected */
      const selectedDateRange = addMissingDates(props.selectedDateRange);
      props.onSelectDateRange(selectedDateRange);
    }
    if (previousStep !== undefined) {
      props.trackingEvent(
        `Filter Interaction (Desktop)`,
        `${Steps[step] !== 'None' ? Steps[step] : 'Filter'} ${
          Steps[step] !== 'None' ? 'Opened' : 'Closed'
        }`
      );
    }
  }, [step]);

  const onCancel = () => {
    setStep(Steps.None);
  };

  const selectedDestinationsArray = () => {
    if (typeof props.selectedDestinations === 'string') {
      return [];
    }

    const destinations = props.destinations.filter((destination) =>
      props.selectedDestinations?.includes(destination.id)
    );

    return destinations.map((destination) => destination.name);
  };

  const detectClickRef = useDetectClickOutside({
    onTriggered: () => setStep(Steps.None)
  });

  const updateTargetRect = () => {
    if (step === Steps.Destination) {
      setTargetRect(getRefRect(whereButtonRef));
    } else if (step === Steps.Dates) {
      setTargetRect(getRefRect(whenButtonRef));
    } else if (step === Steps.Duration) {
      setTargetRect(getRefRect(howLongButtonRef));
    } else {
      setTargetRect(null);
    }
  };

  useEffect(updateTargetRect, [
    step,
    props.selectedDestinations,
    props.selectedDateRange,
    props.selectedDurationRange
  ]);

  const containerRect = getRefRect(expeditionPlannerContainerRef);

  const selectedDurationRangeArray = () =>
    props?.selectedDurationRange && Array.isArray(props.selectedDurationRange)
      ? props.selectedDurationRange.map((v) => durationValuesToString(v))
      : [];

  return (
    <div
      className="relative flex justify-center col-span-8 col-start-3"
      ref={detectClickRef}
    >
      <div
        className={clsx('relative flex w-full transition-colors p1', {
          'bg-white': step === Steps.None,
          'bg-hx-light-gray': step !== Steps.None
        })}
      >
        {targetRect && containerRect && (
          <div
            className="absolute z-0 transition-all bg-white"
            style={{
              left: `${targetRect.left - containerRect.left - 9}px`,
              width: `${targetRect.width - 8}px`,
              height: `calc(100% - 10px)`,
              top: '5px'
            }}
          ></div>
        )}
        <div
          ref={expeditionPlannerContainerRef}
          className="relative z-10 flex items-center w-full"
        >
          <div className="flex justify-center" role="tablist">
            <div className="mr-1.5 ml-4">
              <ExpeditionPlannerDesktopButton
                ref={whereButtonRef}
                label={props.i18n.whereLabel}
                isOpen={step === Steps.Destination}
                hideBorder={step === Steps.Destination}
                selectionText={
                  props.selectedDestinations === 'all'
                    ? props.i18n.allDestinations
                    : listSummary({
                        list: selectedDestinationsArray(),
                        maxCharCount: 20,
                        emptyText: props.i18n.whereEmptyText,
                        moreText: 'more'
                      })
                }
                onClick={() =>
                  step !== Steps.Destination
                    ? setStep(Steps.Destination)
                    : setStep(Steps.None)
                }
                onClearSelection={() => props.onSelectDestinations(null)}
              />
            </div>
            <div className="mx-1.5">
              <ExpeditionPlannerDesktopButton
                ref={whenButtonRef}
                label={props.i18n.whenLabel}
                isOpen={step === Steps.Dates}
                hideBorder={step === Steps.Dates}
                selectionText={
                  props.selectedDateRange
                    ? selectedDataRangeText(props.selectedDateRange, locale)
                    : props.i18n.whenEmptyText
                }
                onClick={() =>
                  step !== Steps.Dates
                    ? setStep(Steps.Dates)
                    : setStep(Steps.None)
                }
                onClearSelection={() => props.onSelectDateRange(null)}
              />
            </div>
            <div className="mx-1.5">
              <ExpeditionPlannerDesktopButton
                ref={howLongButtonRef}
                label={props.i18n.durationLabel}
                isOpen={step === Steps.Duration}
                hideBorder={true}
                selectionText={
                  props.selectedDurationRange?.length === 0
                    ? props.i18n.durationEmptyText
                    : listSummary({
                        list: selectedDurationRangeArray(),
                        maxCharCount: 20,
                        emptyText: props.i18n.durationEmptyText,
                        moreText: 'more'
                      })
                }
                onClick={() =>
                  step !== Steps.Duration
                    ? setStep(Steps.Duration)
                    : setStep(Steps.None)
                }
                onClearSelection={() => props.onSelectDurationRange(null)}
              />
            </div>
          </div>
          <div className="flex justify-end w-full py-4 mr-4">
            <Button
              ref={searchButtonRef}
              mode={ButtonModes.primary}
              onClick={() => {
                void router.push(props.resultUrl);
                setStep(Steps.None);
              }}
            >
              <span className="mr-1">{props.i18n.searchButtonText}</span>
              <span>
                <Icon icon={SearchLine} fill="white" />
              </span>
            </Button>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {step === Steps.Destination && (
          <ExpeditionPlannerDesktopWindow
            key="where"
            onCancel={onCancel}
            onContinue={() => setStep(step + 1)}
          >
            <DestinationFilter
              destinations={props.destinations}
              selectedIds={
                typeof props.selectedDestinations !== 'string'
                  ? props.selectedDestinations
                  : null
              }
              onChange={props.onSelectDestinations}
            />
          </ExpeditionPlannerDesktopWindow>
        )}
        {step === Steps.Dates && (
          <ExpeditionPlannerDesktopWindow
            key="when"
            onCancel={onCancel}
            onContinue={() => setStep(step + 1)}
          >
            <DateFilter
              filterState={props.selectedDateRange}
              onChange={(items) => props.onSelectDateRange(items)}
            />
          </ExpeditionPlannerDesktopWindow>
        )}
        {step === Steps.Duration && (
          <ExpeditionPlannerDesktopWindow
            key="how-long"
            onCancel={onCancel}
            onContinue={() => {
              searchButtonRef.current?.focus();
              setStep(Steps.None);
            }}
          >
            <ExpeditionPlannerDesktopDuration
              filterState={props.selectedDurationRange}
              onChange={(items) => props.onSelectDurationRange(items)}
            />
          </ExpeditionPlannerDesktopWindow>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ExpeditionPlannerDesktop;
