import { RefObject, useEffect, useState } from 'react';

export const useIsVisible = <T extends HTMLElement>(
  ref: RefObject<T> | null,
  threshold = 0.0
) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer =
    typeof window !== 'undefined' &&
    new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      { threshold }
    );

  useEffect(() => {
    if (!ref?.current || !observer) {
      return undefined;
    }

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};
