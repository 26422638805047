import {
  documentToReactComponents,
  Options
} from '@contentful/rich-text-react-renderer';
import { Block, BLOCKS, Inline, INLINES } from '@contentful/rich-text-types';
import { Heading, List } from '@hurtigruten/design-system-components';
import { ReactElement } from 'react';

export const prependLocale = (locale: string, path: string) => {
  if (path.startsWith('mailto') || path.startsWith('tel:')) {
    return path;
  }

  const isAbsolute =
    path.indexOf('http://') === 0 || path.indexOf('https://') === 0;
  const containsLocale = path.indexOf(locale) !== -1;

  if (isAbsolute || containsLocale) {
    return path;
  }

  const startsWithSlash = path.startsWith('/');

  return `/${locale}${startsWithSlash ? '' : '/'}${path}`;
};

export const renderContentfulHeading = (
  node: Block | Inline,
  headingLevel: '1' | '2' | '3' | '4' | '5',
  appearance?: 'headline-3' | 'headline-5'
) => {
  if (node.content[0].nodeType === 'text') {
    return (
      <Heading
        appearance={appearance}
        level={headingLevel}
        text={node.content[0].value}
      />
    );
  }
  return null;
};

/* Obviously, it would be ideal if we could standardise all text formatting 
(in DS preferably) here, but that might take a bit of time. Because links are
appearing quite small in some formatting (EditorialTextBlock) there is an
override that can be applied for styling hyperlinks

This is a bit messy,  but if you 
provide 'hyperlinkEnum' as a boolean 'true' in the options block,
this will then allow you to override [INLINES.HYPERLINK] in the calling function
as part of the renderNode block. */

type ExtendedOptions = Options & {
  /**
   * If provided, overrides the default hyperlink render
   */
  overrideHyperlink?: boolean;
};

const renderContentfulDocumentToReactComponents = (
  doc: Contentful.Document,
  locale: TLocale,
  options?: ExtendedOptions
) => {
  let hyperlinkEnum: INLINES | any = INLINES.HYPERLINK;
  const { overrideHyperlink = false, ...otherOptions } = options ?? {};
  if (overrideHyperlink) {
    hyperlinkEnum = null;
  }

  return documentToReactComponents(doc, {
    ...(otherOptions ?? {}),
    renderNode: {
      [hyperlinkEnum]: ({ data }, children) => (
        <a href={prependLocale(locale, data.uri)}>{children}</a>
      ),
      [BLOCKS.UL_LIST]: (_, c) => {
        const children = c as ReactElement<{ children: ReactElement[] }>[];
        return children ? (
          <div className="ml-4">
            <List kind="unordered">
              {children.map((child, idx) => (
                <List.Item key={`${idx}`}>{child.props.children[0]}</List.Item>
              ))}
            </List>
          </div>
        ) : null;
      },
      [BLOCKS.OL_LIST]: (_, c) => {
        const children = c as ReactElement<{ children: ReactElement[] }>[];
        return children ? (
          <div className="ml-4">
            <List kind="ordered">
              {children.map((child, idx) => (
                <List.Item key={`${idx}`}>{child.props.children[0]}</List.Item>
              ))}
            </List>
          </div>
        ) : null;
      },
      ...((otherOptions && otherOptions.renderNode) ?? {})
    }
  });
};

export default renderContentfulDocumentToReactComponents;
