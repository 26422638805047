export interface MustSeeDestinationCard {
  __typename?: 'MustSeeDestinationCard';
  slug: string;
  name: string;
  image: {
    title: string;
    url: string;
  };
}

export const toOldDestinationModel = (destination: MustSeeDestinationCard) => ({
  name: destination.name,
  slug: destination.slug,
  heroBanner: {
    heroImage: {
      image: {
        file: {
          url: destination.image.url
        }
      }
    }
  }
});

export const toNewDestinationModel = (destination: MustSeeDestinationCard) => ({
  name: destination.name,
  slug: destination.slug,
  introHero: {
    image: {
      url: destination.image.url
    }
  }
});
