/* eslint-disable no-nested-ternary */
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import React from 'react';
import NextLink from 'next/link';
import clsx from 'clsx';
import { Document } from '@contentful/rich-text-types';

import { Fluid } from '@components';
import { useLocale } from '@hooks';
import { ContentfulImage, PageSection } from '@atoms';
import {
  LandingPageCampaign,
  LandingPageStory
} from '@content/models/landingPage';
import { isLandingPageStory } from '@content/utils/typeguards';
import { generateResponsiveSizesString } from '@src/utils';
import {
  mapLocaleToCampaignsUri,
  mapLocaleToStoriesUri
} from '@src/utils/mappers/uriMappers';
import ContentfulXRAY from '@components/contentful/ContentfulXray';

const WhyExpeditions = ({
  title,
  shortDescription,
  storiesAndCampaigns,
  isDark
}: {
  isDark?: boolean;
  shortDescription?: Document | null;
  title: string;
  storiesAndCampaigns: (LandingPageStory | LandingPageCampaign)[];
}) => {
  const locale = useLocale();

  const getDescription = (
    storyOrCampaign: LandingPageStory | LandingPageCampaign
  ) => {
    let description: string = '';

    if (isLandingPageStory(storyOrCampaign) && storyOrCampaign.body) {
      description = documentToPlainTextString(storyOrCampaign.body);
    }

    if (!description && storyOrCampaign.bodyText) {
      description = storyOrCampaign.bodyText;
    }

    if (description) {
      return <p className="font-normal line-clamp-2">{description}</p>;
    }
    return null;
  };

  const checkModifiedType = (typename: string | undefined) => {
    let type = typename;
    if (typename === 'LandingPageStory') {
      type = 'Story';
    } else if (typename === 'LandingPageCampaign') {
      type = 'CampaignType2';
    }
    return type;
  };

  return (
    <PageSection
      backgroundColor={isDark ? 'hx-caviar' : 'gray'}
      title={title}
      leadText={
        shortDescription
          ? documentToPlainTextString(shortDescription)
          : undefined
      }
    >
      <Fluid className="flex flex-col items-start">
        <div className="relative flex flex-col w-full gap-2 -m-2 tablet:gap-6 tablet:flex-row">
          {storiesAndCampaigns.map((storyOrCampaign, i) => (
            <ContentfulXRAY
              key={`story_${i}`}
              title={storyOrCampaign.title}
              contentType={checkModifiedType(storyOrCampaign.__typename)}
              entryID={storyOrCampaign.sysId}
            >
              <NextLink
                locale={locale}
                href={
                  isLandingPageStory(storyOrCampaign)
                    ? `/${mapLocaleToStoriesUri(locale)}/${
                        storyOrCampaign.slug
                      }`
                    : `/${mapLocaleToCampaignsUri(locale)}/${
                        storyOrCampaign.slug
                      }`
                }
                passHref
              >
                <a className="flex-1 link-wrapper group">
                  <div
                    key={storyOrCampaign.title}
                    className="relative flex flex-col flex-1 w-full p-2 overflow-hidden group-hover:bg-hx-dark-card-hover whyHRG-card"
                  >
                    <div
                      className={clsx(
                        'cursor-pointer h-full flex flex-col justify-between group-hover:text-white',
                        {
                          'text-white': isDark,
                          'text-black': !isDark
                        }
                      )}
                    >
                      <div>
                        <div className="relative overflow-hidden h-[402px] laptop:h-[350px]">
                          <ContentfulImage
                            src={storyOrCampaign.image.url}
                            layout="fill"
                            objectFit="cover"
                            objectPosition="center"
                            sizes={generateResponsiveSizesString({
                              vwMobile: '100vw',
                              vwTablet: '50vw',
                              vwLaptop: '33vw'
                            })}
                            alt={storyOrCampaign.title}
                          />
                        </div>
                        <h3 className="pt-4 pb-1 tablet:pb-2 tablet:pt-6 h5-text tracking-tightish group-hover:underline">
                          {storyOrCampaign.title}
                        </h3>
                        {getDescription(storyOrCampaign)}
                      </div>
                    </div>
                  </div>
                </a>
              </NextLink>
            </ContentfulXRAY>
          ))}
        </div>
      </Fluid>
    </PageSection>
  );
};

export default WhyExpeditions;
