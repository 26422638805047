import { useEffect, useMemo, useState } from 'react';
import { isAfter, isBefore, parseJSON } from 'date-fns';

import { getSharedEnvironmentVariables } from '@src/utils/environmentVariables';
import {
  CruiseFilterData,
  DateRange,
  DurationRange,
  PlannerDestination,
  PlannerProps
} from '@src/types/expeditionPlanner';
import { useLocale, useMediaQuery, useTranslate } from '@hooks';
import { common, expeditionPlanner } from '@microcopies';
import { breakpoints } from '@src/utils';
import { mapLocaleToCruisesUri } from '@src/utils/mappers/uriMappers';

import ExpeditionPlannerDesktop from './ExpeditionPlannerDesktop';
import MobileExpeditionPlanner from './MobileExpeditionPlanner';

interface CruiseFilterDataWithParsedDates extends CruiseFilterData {
  parsedDepartureDates: Date[] | null;
}

const filterByDestination = (
  cruise: CruiseFilterDataWithParsedDates,
  destinationIds: string[]
) => cruise.destinationIds.some((id) => destinationIds.includes(id));

const filterByDepartureDate = (
  cruise: CruiseFilterDataWithParsedDates,
  dateRange: DateRange
) => {
  if (!cruise.parsedDepartureDates) {
    return false;
  }

  return cruise.parsedDepartureDates.some(
    (d) =>
      !(
        isBefore(d, dateRange.start) ||
        (dateRange.end ? isAfter(d, dateRange.end) : false)
      )
  );
};

const filterByDuration = (
  cruise: CruiseFilterDataWithParsedDates,
  durationRange: DurationRange[]
) =>
  durationRange.some((duration) => {
    let durationFilter;
    if (cruise.duration) {
      durationFilter =
        cruise.duration >= duration.min && cruise.duration <= duration.max;
    }
    /* for the 22+ filter the min and max values are both 22 
    so ignore the max value */
    if (cruise.duration && duration.min === duration.max) {
      durationFilter = cruise.duration >= duration.min;
    }
    return cruise.duration ? durationFilter : false;
  });

const NewExpeditionPlanner = ({
  destinations,
  cruises
}: {
  destinations: PlannerDestination[];
  cruises: CruiseFilterData[];
}) => {
  const [selectedDestinations, setSelectedDestinations] = useState<
    string[] | 'all' | null
  >(null);
  const [selectedDurationRange, setSelectedDurationRange] = useState<
    DurationRange[] | 'all' | null
  >(null);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | null>(
    null
  );

  const commonTranslate = useTranslate(common, (x) => x.common);
  const plannerTranslate = useTranslate(
    expeditionPlanner,
    (x) => x.expeditionPlanner
  );

  const parsedCruises = useMemo<CruiseFilterDataWithParsedDates[]>(
    () =>
      cruises.map((x) => ({
        ...x,
        parsedDepartureDates: x.departureDates
          ? x.departureDates.map(parseJSON)
          : null
      })),
    [cruises]
  );

  const results = useMemo<CruiseFilterDataWithParsedDates[]>(() => {
    let result = [...parsedCruises];
    if (selectedDestinations && typeof selectedDestinations !== 'string') {
      result = result.filter((x) =>
        filterByDestination(x, selectedDestinations)
      );
    }
    if (selectedDateRange) {
      result = result.filter((x) =>
        filterByDepartureDate(x, selectedDateRange)
      );
    }
    if (selectedDurationRange && typeof selectedDurationRange !== 'string') {
      result = result.filter((x) => filterByDuration(x, selectedDurationRange));
    }
    return result;
  }, [
    selectedDestinations,
    selectedDurationRange,
    selectedDateRange,
    cruises,
    destinations
  ]);

  const locale = useLocale();
  const { WEBSITE_URL } = getSharedEnvironmentVariables();

  const baseUrl =
    typeof window !== 'undefined'
      ? `${window?.location.protocol}//${window?.location.host}`
      : WEBSITE_URL || 'https://www.travelhx.com';
  const resultUrl = new URL(
    `${baseUrl}/${locale}/${mapLocaleToCruisesUri(locale)}/`
  );
  resultUrl.searchParams.set('forceRefresh', 'true');
  if (
    selectedDestinations &&
    typeof selectedDestinations !== 'string' &&
    selectedDestinations.length > 0
  ) {
    resultUrl.searchParams.set('destinations', selectedDestinations?.join(','));
  }
  if (
    selectedDurationRange &&
    typeof selectedDurationRange !== 'string' &&
    selectedDurationRange.length > 0
  ) {
    resultUrl.searchParams.set(
      'durationIntervals',
      selectedDurationRange.map(({ min, max }) => `${min}-${max}`).join(',')
    );
  }
  if (selectedDateRange?.start) {
    resultUrl.searchParams.set(
      'departureDate',
      selectedDateRange.start.getTime().toString()
    );
  }
  if (selectedDateRange?.end) {
    resultUrl.searchParams.set(
      'returnDate',
      selectedDateRange.end.getTime().toString()
    );
  }

  const props: PlannerProps = {
    resultCount: results.length,
    resultUrl: resultUrl.toString(),
    destinations,
    selectedDestinations,
    selectedDateRange,
    selectedDurationRange,
    onSelectDestinations: (val) => setSelectedDestinations(val),
    onSelectDateRange: (val) => setSelectedDateRange(val),
    onSelectDurationRange: (val) => setSelectedDurationRange(val),
    onDone: () => {},
    trackingEvent: () => {},
    i18n: {
      whereLabel: plannerTranslate((x) => x.whereLabel),
      whereEmptyText: plannerTranslate((x) => x.whereEmptyText),
      whenLabel: plannerTranslate((x) => x.whenLabel),
      whenEmptyText: plannerTranslate((x) => x.whenEmptyText),
      durationLabel: plannerTranslate((x) => x.durationLabel),
      durationEmptyText: plannerTranslate((x) => x.durationEmptyText),
      cancelButtonText: commonTranslate((x) => x.cancel),
      clearButtonText: commonTranslate((x) => x.clear),
      nextButtonText: commonTranslate((x) => x.next),
      searchButtonText: plannerTranslate((x) => x.searchButtonText, {
        count: results.length.toString()
      }),
      selectAll: commonTranslate((x) => x.selectAll),
      allDestinations: plannerTranslate((x) => x.allDestinations),
      allDurations: plannerTranslate((x) => x.allDurations),
      searchPlaceholder: plannerTranslate((x) => x.searchPlaceholder)
    }
  };

  const isMobile = useMediaQuery(breakpoints.tablet, true);

  // Don't render until hydration to prevent flashing wrong type of planner
  const [shouldRender, setShouldRender] = useState(false);
  useEffect(() => {
    if (window) {
      setShouldRender(true);
    }
  }, []);

  if (!shouldRender) {
    return null;
  }

  return isMobile ? (
    <MobileExpeditionPlanner {...props} />
  ) : (
    <ExpeditionPlannerDesktop {...props} />
  );
};

export default NewExpeditionPlanner;
