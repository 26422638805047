import { Document } from '@contentful/rich-text-types';

import { CruiseCard } from './cruiseCard';
import { TPromoBanner } from './editorialContent';
import { MustSeeDestinationCard } from './mustSeeDestinationCard';
import { OfferWrapper } from './offerWrapper';

export interface LandingPage {
  heroGalleryShouldAutoplay: boolean;
  banners: {
    url: string | null;
    videoUrl: string | null;
    title: string | null;
    subHeading: string | null;
    description: string | null;
    body: Document | null;
    offer: null | {
      expiryDate: string;
    };
    cta: null | {
      targetId: string;
      slug: string;
      label: string;
    };
  }[];
  promoBanner?: TPromoBanner | null;
  sections: LandingPageSection[];
}

export const Sections = {
  OfferCarousel: 'Offer Carousel',
  WhyHRG: 'Why Hurtigruten Carousel',
  MustSeeDestinations: 'Must See Destinations',
  FeaturedVoyages: 'Featured Voyages',
  Stories: 'Stories Carousel'
} as const;

type BaseSection = {
  __typename: string;
  internalName?: string;
  title: string;
  shortDescription: Document | null;
};

type OfferSection = BaseSection & {
  sectionType: typeof Sections['OfferCarousel'];
  subsections: OfferWrapper[];
};
type WhyHRG = BaseSection & {
  sectionType: typeof Sections['WhyHRG'];
  subsections: LandingPageStory[] | LandingPageCampaign[];
};
export type MustSeeDestinations = BaseSection & {
  sectionType: typeof Sections['MustSeeDestinations'];
  subsections: MustSeeDestinationCard[];
};
type FeaturedVoyages = BaseSection & {
  sectionType: typeof Sections['FeaturedVoyages'];
  subsections: CruiseCard[];
};
type Stories = BaseSection & {
  sectionType: typeof Sections['Stories'];
  subsections: LandingPageStory[];
};

export type LandingPageSection =
  | OfferSection
  | WhyHRG
  | MustSeeDestinations
  | FeaturedVoyages
  | Stories;

export interface LandingPageCampaign {
  __typename: string;
  sysId: string;
  title: string;
  image: {
    url: string;
    title: string;
  };
  bodyText: string | null;
  slug: string;
}

export interface LandingPageStory {
  __typename: string;
  sysId: string;
  title: string;
  image: {
    url: string;
    title: string;
  };
  teaser: Document | null;
  body: Document | null;
  bodyText: string | null;
  slug: string;
}

export const toTStory = (story: LandingPageStory): Contentful.TStory =>
  ({
    sysId: story.sysId,
    __typename: story.__typename,
    title: story.title,
    slug: story.slug,
    heroImage: {
      image: {
        file: {
          url: story.image.url
        },
        title: story.image.title
      }
    },
    teaser: story.teaser,
    body: story.body
  } as Contentful.TStory);
