import clsx from 'clsx';
import { Checkbox } from '@hurtigruten/design-system-components';
import { useState } from 'react';

import { ContentfulImage, Icon } from '@atoms';
import { CheckLine } from '@components/icons/System';
import { PlannerDestination } from '@src/types/expeditionPlanner';
import { useTranslate } from '@hooks';
import { common } from '@microcopies';

const DestinationCheckbox = ({
  name,
  imageUrl,
  isChecked,
  id,
  onChange
}: {
  name: string;
  imageUrl: string;
  isChecked: boolean;
  onChange: (v: boolean) => void;
  id: string;
}) => {
  const labelId = `${id}__label`;

  return (
    <div
      id={id}
      role="checkbox"
      aria-checked={isChecked}
      tabIndex={0}
      aria-labelledby={labelId}
      className="cursor-pointer outline-offset-4"
      onClick={() => onChange(!isChecked)}
      onKeyDown={(e) => {
        if (e.key === ' ') {
          e.preventDefault();
          onChange(!isChecked);
        }
      }}
    >
      <ContentfulImage
        src={imageUrl}
        width={160}
        height={100}
        layout="responsive"
      />
      <div className="flex mt-2">
        <label className="flex-1 text-xs cursor-pointer" id={labelId}>
          {name}
        </label>
        <div
          className={clsx(
            'flex items-center justify-center w-6 h-6 overflow-hidden border-2 border-primary-hx-indigo',
            {
              'bg-primary-hx-indigo': isChecked,
              'bg-white': !isChecked
            }
          )}
        >
          <Icon icon={CheckLine} className="text-white" />
        </div>
      </div>
    </div>
  );
};

const DestinationFilter = ({
  destinations,
  selectedIds,
  onChange
}: {
  destinations: PlannerDestination[];
  selectedIds: string[] | 'all' | null;
  onChange: (ids: string[] | 'all' | null) => void;
}) => {
  const [selectAll, setSelectAll] = useState(selectedIds === 'all');
  const translate = useTranslate(common, (x) => x.common);

  return (
    <div>
      <div className="flex justify-between mb-6">
        <h6 className="font-medium leading-5 text-md">
          {translate((x) => x.destinations)}
        </h6>
        <Checkbox
          id="select_all_destinations"
          checked={selectAll}
          label={translate((x) => x.selectAll)}
          value="true"
          onClick={() => {
            const newSelectAll = !selectAll;
            setSelectAll(newSelectAll);
            onChange(newSelectAll ? 'all' : null);
          }}
        />
      </div>
      <div className="grid laptop:grid-cols-6 tablet:grid-cols-4 gap-x-6 gap-y-10">
        {destinations.map((destination) => (
          <div key={destination.id}>
            <DestinationCheckbox
              name={destination.name}
              imageUrl={destination.imageUrl}
              id={destination.id}
              isChecked={
                (selectAll ||
                  selectedIds === 'all' ||
                  selectedIds?.includes(destination.id)) ??
                false
              }
              onChange={(v) => {
                let result = selectedIds === 'all' ? [] : selectedIds ?? [];
                if (selectAll) {
                  result = destinations.map((x) => x.id);
                  setSelectAll(false);
                }
                if (v) {
                  result = [...result, destination.id];
                } else {
                  result = result.filter((id) => id !== destination.id);
                }
                onChange(result.length === 0 ? null : result);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DestinationFilter;
