/* eslint-disable max-len */
// Auto generated file. Do not edit.

export const expeditionPlanner: Record<string, TExpeditionPlanner> = {
  'en-AU': {
    expeditionPlanner: {
      clearAll: 'Clear all',
      header: 'What are you interested in?',
      showCruises: 'Show cruises',
      topics: 'Topics',
      destinations: 'Destinations',
      whereLabel: 'Where',
      whereEmptyText: 'Any destination',
      whenLabel: 'When',
      durationLabel: 'Duration',
      durationEmptyText: 'Any duration',
      searchButtonText: 'Search {count} cruises',
      whenEmptyText: 'Any date',
      searchPlaceholder: 'Explore our cruises',
      allDestinations: 'Any destination',
      allDurations: 'Any duration',
      next: 'Next',
      cancel: 'Cancel'
    }
  },
  'en-GB': {
    expeditionPlanner: {
      clearAll: 'Clear all',
      header: 'What are you interested in?',
      showCruises: 'Show cruises',
      topics: 'Topics',
      destinations: 'Destinations',
      whereLabel: 'Where',
      whereEmptyText: 'Any destination',
      whenLabel: 'When',
      durationLabel: 'Duration',
      durationEmptyText: 'Any duration',
      searchButtonText: 'Search {count} cruises',
      whenEmptyText: 'Any date',
      searchPlaceholder: 'Explore our cruises',
      allDestinations: 'Any destination',
      allDurations: 'Any duration',
      next: 'Next',
      cancel: 'Cancel'
    }
  },
  'en-US': {
    expeditionPlanner: {
      clearAll: 'Clear all',
      header: 'What are you interested in?',
      showCruises: 'Show cruises',
      topics: 'Topics',
      destinations: 'Destinations',
      whereLabel: 'Where',
      whereEmptyText: 'Any destination',
      whenLabel: 'When',
      durationLabel: 'Duration',
      durationEmptyText: 'Any duration',
      searchButtonText: 'Search {count} cruises',
      whenEmptyText: 'Any date',
      searchPlaceholder: 'Explore our cruises',
      allDestinations: 'Any destination',
      allDurations: 'Any duration',
      next: 'Next',
      cancel: 'Cancel'
    }
  },
  'de-DE': {
    expeditionPlanner: {
      clearAll: 'Alle löschen',
      header: 'Woran sind Sie interessiert?',
      showCruises: 'Expeditions-Seereisen anzeigen',
      topics: 'Themen',
      destinations: 'Reiseziele',
      whereLabel: 'Wohin',
      whereEmptyText: 'Beliebig',
      whenLabel: 'Wann',
      durationLabel: 'Dauer',
      durationEmptyText: 'Beliebig',
      searchButtonText: 'Suche {count} Expeditions-Seereisen',
      whenEmptyText: 'Beliebig',
      searchPlaceholder: 'Entdecken Sie unsere Reisen',
      allDestinations: 'Beliebig',
      allDurations: 'Jeder Zeitraum',
      next: 'Nächste',
      cancel: 'Abbrechen'
    }
  },
  'gsw-CH': {
    expeditionPlanner: {
      clearAll: 'Alle löschen',
      header: 'Woran sind Sie interessiert?',
      showCruises: 'Expeditions-Seereisen anzeigen',
      topics: 'Themen',
      destinations: 'Reiseziele',
      whereLabel: 'Wohin',
      whereEmptyText: 'Beliebig',
      whenLabel: 'Wann',
      durationLabel: 'Dauer',
      durationEmptyText: 'Beliebig',
      searchButtonText: 'Suche {count} Expeditions-Seereisen',
      whenEmptyText: 'Beliebig',
      searchPlaceholder: 'Entdecken Sie unsere Reisen',
      allDestinations: 'Beliebig',
      allDurations: 'Jeder Zeitraum',
      next: 'Nächste',
      cancel: 'Abbrechen'
    }
  },
  'fr-FR': {
    expeditionPlanner: {
      clearAll: 'Effacer tout',
      header: 'Affinez votre recherche',
      showCruises: 'Afficher les croisières',
      topics: 'Sujets',
      destinations: 'Destinations',
      whereLabel: 'Où',
      whereEmptyText: 'Sélectionnez une destination',
      whenLabel: 'Quand',
      durationLabel: 'Combien de temps',
      durationEmptyText: 'Sélectionnez une durée',
      searchButtonText: 'Recherchez parmi {count} croisières',
      whenEmptyText: 'Sélectionnez une date',
      searchPlaceholder: 'Explorez nos croisières',
      allDestinations: 'Toutes les destinations',
      allDurations: 'Toutes les durées',
      next: 'Suivant',
      cancel: 'Annuler'
    }
  },
  'da-DK': {
    expeditionPlanner: {
      clearAll: 'Ryd alle',
      header: 'Hvad er du interesseret i?',
      showCruises: 'Vis krydstogter',
      topics: 'Emner',
      destinations: 'Destinationer',
      whereLabel: 'Hvor',
      whereEmptyText: 'Vælg en destination',
      whenLabel: 'Hvornår',
      durationLabel: 'Varighed',
      durationEmptyText: 'Vælg antal dage',
      searchButtonText: 'Søg blandt {count} krydstogter',
      whenEmptyText: 'Vælg en dato',
      searchPlaceholder: 'Se vores krydstogter',
      allDestinations: 'Alle destinationer',
      allDurations: 'Alle varigheder',
      next: 'Videre',
      cancel: 'Anuller'
    }
  },
  'sv-SE': {
    expeditionPlanner: {
      clearAll: 'Rensa alla',
      header: 'Vad är du intresserad av?',
      showCruises: 'Visa kryssningar',
      topics: 'Ämnen',
      destinations: 'Destinationer',
      whereLabel: 'Var',
      whereEmptyText: 'Välj ett resmål',
      whenLabel: 'När',
      durationLabel: 'Längd',
      durationEmptyText: 'Välj reslängd',
      searchButtonText: 'Sök {count} kryssningar',
      whenEmptyText: 'Välj ett datum',
      searchPlaceholder: 'Läs mer om våra kryssningar',
      allDestinations: 'Alla resmål',
      allDurations: 'Alla reslängder',
      next: 'Videre',
      cancel: 'Avbryt'
    }
  },
  'nb-NO': {
    expeditionPlanner: {
      clearAll: 'Tøm felter',
      header: 'Hva er du interessert i?',
      showCruises: 'Vis cruise',
      topics: 'Emner',
      destinations: 'Destinasjoner',
      whereLabel: 'Hvor',
      whereEmptyText: 'Velg destinasjon',
      whenLabel: 'Når',
      durationLabel: 'Varighet',
      durationEmptyText: 'Velg antall dager',
      searchButtonText: 'Søk {count} cruise',
      whenEmptyText: 'Velg en dato',
      searchPlaceholder: 'Utforsk cruisene våre',
      allDestinations: 'Alle destinasjoner',
      allDurations: 'Alle varigheter',
      next: 'Videre',
      cancel: 'Avbryt'
    }
  },
  'en': {
    expeditionPlanner: {
      clearAll: 'Clear all',
      header: 'What are you interested in?',
      showCruises: 'Show cruises',
      topics: 'Topics',
      destinations: 'Destinations',
      whereLabel: 'Where',
      whereEmptyText: 'Any destination',
      whenLabel: 'When',
      durationLabel: 'Duration',
      durationEmptyText: 'Any duration',
      searchButtonText: 'Search {count} cruises',
      whenEmptyText: 'Any date',
      searchPlaceholder: 'Explore our cruises',
      allDestinations: 'Any destination',
      allDurations: 'Any duration',
      next: 'Next',
      cancel: 'Cancel'
    }
  }
}

export type TExpeditionPlanner = {
  expeditionPlanner: {
    clearAll: string;
    header: string;
    showCruises: string;
    topics: string;
    destinations: string;
    whereLabel: string;
    whereEmptyText: string;
    whenLabel: string;
    durationLabel: string;
    durationEmptyText: string;
    searchButtonText: string;
    whenEmptyText: string;
    searchPlaceholder: string;
    allDestinations: string;
    allDurations: string;
    next: string;
    cancel: string;
  };
}
