import { ICalendarIconProps } from '@src/types/expeditionPlanner';

const Calendar1Icon = (props: ICalendarIconProps) => {
  const { colour } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
    >
      <path
        d="M22.8 2.8H18.4V0H16.8V2.8H7.2V0H5.6V2.8H1.2C0.881854 2.80037 0.576845 2.92692 0.351881 3.15188C0.126918 3.37684 0.000370522 3.68185 0 4V20.8C0.000370522 21.1181 0.126918 21.4232 0.351881 21.6481C0.576845 21.8731 0.881854 21.9996 1.2 22H22.8C23.1181 21.9996 23.4232 21.8731 23.6481 21.6481C23.8731 21.4232 23.9996 21.1181 24 20.8V4C23.9996 3.68185 23.8731 3.37684 23.6481 3.15188C23.4232 2.92692 23.1181 2.80037 22.8 2.8ZM22.4 20.4H1.6V4.4H5.6V6.4H7.2V4.4H16.8V6.4H18.4V4.4H22.4V20.4Z"
        fill={colour}
      />
      <path d="M6.40005 9.2H4.80005V10.8H6.40005V9.2Z" fill={colour} />
      <path d="M10.8002 9.2H9.2002V10.8H10.8002V9.2Z" fill={colour} />
      <path d="M14.8 9.2H13.2V10.8H14.8V9.2Z" fill={colour} />
      <path d="M19.2001 9.2H17.6001V10.8H19.2001V9.2Z" fill={colour} />
    </svg>
  );
};

export default Calendar1Icon;
